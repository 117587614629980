<template>
  <el-container style="height:calc(100vh);">
    <el-main style="width:75%; height:calc(100vh);padding:0 !important;"> 
      <el-container style="height:calc(100vh);">
        <el-aside class="aside" width="150px">
          <el-menu class="menu">
            <el-menu-item v-for=" (item,key) in item" :key="key" class="menu_item">
              <template slot="title">
                <div :class="activeItem===item.value?'nav1':'nav'" @click="getkey(item.value)">
                  <span>{{item.text}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container v-if="activeItem==0" class="content_big">
            <el-aside class="content_big_aside" > 
             <div class="content_big_title">业绩填单类型</div>
            <el-menu class="content_big_menu">
               <el-button type="primary" @click="dialogVisible1 = true">+添加分类</el-button>
               <div class="table_title">
                    <div>分类名称</div>
                    <div>单次业绩提成</div>
                    <div>添加事件</div>
                    <div>操作</div>
                </div>
                <table class="table_big">
                   <tr>
                    <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                   </tr>
                   <tr class="table_big_nono">
                    <div class="table_big_min">
                        <div>
                             <img src="@/assets/images/index/nonono.png" alt="">
                         </div>
                        <div>暂无数据</div>
                    </div>
                  
                   </tr>
                </table>
            </el-menu>
        </el-aside>
      </el-container>
       <el-container  v-if="activeItem==1" class="content_big">
            <el-aside class="content_big_aside" > 
             <div class="content_big_title">业绩核准</div>
            <el-menu class="content_big_menu2">
              <div>员工：<el-input v-model="item.num" class="th_inp" size="mini"
                placeholder="请输入员工姓名/手机号"></el-input>
                 客户：<el-input v-model="item.num" class="th_inp" size="mini"
                placeholder="请输入客户姓名/手机号"></el-input>
                <button class="but1">查询</button> <button class="but2">取消</button></div>
            </el-menu>
            <el-menu class="content_big_menu2">
                <div class="content_big1">
                    <div :class="state===1 ?'title2' :'title1'" @click="gettitle(1)">待审核</div>
                    <div :class="state===2 ?'title2' :'title1'"  @click="gettitle(2)">核准通过</div>
                    <div :class="state===3 ?'title2' :'title1'"  @click="gettitle(3)">核准不对</div>
                </div>
                <div class="table_title2">
                    <div>员工</div>
                    <div>业绩填单类型</div>
                    <div>业绩提成</div>
                    <div>员工备注</div>
                    <div>图片</div>
                    <div>操作</div>
                    </div>
                <table class="table_big">
                   <tr>
                    <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                   </tr>
                   <tr class="table_big_nono">
                    <div class="table_big_min">
                        <div>
                             <img src="@/assets/images/index/nonono.png" alt="">
                         </div>
                        <div>暂无数据</div>
                    </div>
                  
                   </tr>
                </table>
            </el-menu>
        </el-aside>
      </el-container>
       <el-container  v-if="activeItem==2" class="content_big">
            <el-aside class="content_big_aside" > 
             <div class="content_big_title">员工业绩</div>
            <el-menu class="content_big_menu2">
              <div>员工：<el-input v-model="item.num" class="th_inp" size="mini"
                placeholder="请输入员工姓名/手机号"></el-input>
               
                <button class="but1">查询</button> <button class="but2">取消</button></div>
            </el-menu>
            <el-menu class="content_big_menu2">
                <div class="content_big1">
                    <div :class="statetwo===1 ?'title2' :'title1'" @click="gettitletwo(1)">本月业绩</div>
                    <div :class="statetwo===2 ?'title2' :'title1'"  @click="gettitletwo(2)">上月业绩</div>
                    <div :class="statetwo===3 ?'title2' :'title1'"  @click="gettitletwo(3)">本年度业绩</div>
                </div>
                <div class="zongnum">本月员工总业绩：<div class="numH">￥0</div></div>
                <div class="table_title">
                    <div>排名</div>
                    <div>员工</div>
                    <div>员工手机号</div>
                    <div>核准业绩</div>
                    <div>操作</div>
                </div>
                <table class="table_big">
                   <tr>
                    <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                   </tr>
                   <tr class="table_big_nono">
                    <div class="table_big_min">
                        <div>
                             <img src="@/assets/images/index/nonono.png" alt="">
                         </div>
                        <div>暂无数据</div>
                    </div>
                  
                   </tr>
                </table>
            </el-menu>
        </el-aside>
      </el-container>
      <div>
        <!-- 打开添加业绩分类 -->
         <el-dialog title="添加业绩填单分类" :visible.sync="dialogVisible1" width="35%" >
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="填单分类">
                <el-input v-model="formInline.user" placeholder="请输入分类名称"></el-input>
              </el-form-item>
              <el-form-item label="单次提成">
                <el-input v-model="formInline.user" placeholder="元"></el-input>
              </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible1= false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible1 = false">保存</el-button>
            </span>
          </el-dialog>
      </div>
      
      
     
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        item: [
           {value:0,text:'业绩类型'}, 
           {value:1,text:'业绩核准'},
           {value:2,text:'员工业绩'},
        ],
        activeItem:0,
        dialogVisible1: false,
        state:1,
        statetwo:1,
        formInline: {
        iphone: "",
        user: "",
      },
      }
    },

    watch: {
      
    },

    mounted() {
    },
    methods: {
        getkey(key){
            this.activeItem=key
        },
        gettitle(key){
            this.state=key
        },
        gettitletwo(key){
            this.statetwo=key
        }
    }
  };

</script>
<style lang="less" scoped>
  // 左侧导航
  .aside {
    background-color: white;
    border-right: solid 1px #e6e6e6;
    .nav {
      text-align: center;
      span {
        border: 1px solid transparent;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }
    .nav1 {
      text-align: center;
      background-color: white;
      span {
        color: #409eff;
        border: 1px solid #409eff;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }
    .menu_item{
        background-color: white;
    }
    .el-menu{
        border: none !important;
        padding-top: 35px;
    }
  }
  .content_big{
    width: 100%;
    height: 100%;
    padding: 20px;
    .content_big_aside{
        width: 100% !important;
 .content_big_title{
        width: 100%;
        font-size: 25px;
        padding: 20px 0px;
    }
    .content_big_menu{
        width: 95%;
        padding: 30px;
        .table_title{
        width: 100%;
        height: 45px;
        line-height: 45px;
         background-color:#f9f9f9;
         margin-top: 30px;
        div{
            display: inline-block;
            width: 25% !important;
            text-align: center !important;
        }
    }
    .table_big{
        width: 100%;
        border-top:1px solid #e8e8e8;
        border-bottom:1px solid #e8e8e8;
        td{
            text-align: center;
            height: 35px;
            line-height: 35px;
        }
        .table_big_nono{
            width: 100%;
            .table_big_min{
                padding: 70px 0px;
            }
            div{
                width: 100%;
                text-align: center;
                color: #CDCDCD;
                img{
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }
    }
    }
   
  }
  .content_big_menu2{
     width: 95%;
        padding: 30px;
    margin-bottom: 20px;
    .th_inp{
        width: 200px;
    }
    .but1{
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
    }
    .but2{
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
         border-radius: 3px;
         border: 1px solid #999999;
         color: #999999;
    }
    .content_big1{
        .title1{
            display: inline-block;
            width: 100px;
            text-align: center;
            border: 1px solid #999999;
            height: 35px;
            line-height: 35px;
        }
        .title2{
             display: inline-block;
            width: 100px;
            text-align: center;
             height: 35px;
            line-height: 35px;
            background-color: #409eff;
            color: white;
             border: 1px solid #409eff;
        }
    }
    .zongnum{
        padding:30px 0px;
    }
    .numH{
        display: inline-block;
        color: #ff6d00;
        font-weight: 700;
    }
    .table_title{
        width: 100%;
        height: 45px;
        line-height: 45px;
         background-color:#f9f9f9;
        div{
            display: inline-block;
            width: 20% !important;
            text-align: center !important;
        }
    }
     .table_title2{
        margin-top: 30px;
        width: 100%;
        height: 45px;
        line-height: 45px;
         background-color:#f9f9f9;
        div{
            display: inline-block;
            width: 16.6% !important;
            text-align: center !important;
        }
    }
    .table_big{
        width: 100%;
        border-top:1px solid #e8e8e8;
        border-bottom:1px solid #e8e8e8;
        td{
            text-align: center;
            height: 35px;
            line-height: 35px;
        }
        .table_big_nono{
            width: 100%;
            .table_big_min{
                padding: 70px 0px;
            }
            div{
                width: 100%;
                text-align: center;
                color: #CDCDCD;
                img{
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }
  }

</style>
